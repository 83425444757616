.legend-container {
    --width: 1rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
    height: 50%;
    width: var(--width);
    background: linear-gradient(hsl(0,90%,61%), hsl(120,90%,61%));
    box-shadow: 1px 1px 2px grey;
}

.legend-view {
    width: 100%;
    height: 100%;
    position: relative;
}

.legend-view .max-label {
    position: absolute;
    top: 0;
    transform: translate(-110%, -50%);
}

.legend-view .min-label {
    position: absolute;
    bottom: 0;
    transform: translate(-160%, 50%);
}