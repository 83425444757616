
.scroll-indicator {
    --scroll-width: 12.5rem;
    width: var(--scroll-width);
    height: var(--scroll-indicator-height);
    position: absolute;
    bottom: 1rem;
    left: calc(50% - calc(var(--scroll-width) / 2));
    display: flex;
    align-items: center;
}

.scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.scroll-container .line {
    --line-height: .3rem;
    background: grey;
    height: var(--line-height);
    position: absolute;
    top: calc(50% - calc(var(--line-height) / 2));
    width: 100%;
    border-radius: .1rem;
}

.scroll-container .ball {
    --height: 20px;
    width: var(--scroll-indicator-height);
    height: var(--scroll-indicator-height);
    background: lightgray;
    box-shadow: 1px 1px 2px grey;
    position: absolute;
    transform: translate(50%, 0);
    border-radius: 50%;
}