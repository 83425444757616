input[type="file"] {
    display: none;
}

.upload {
    width: 100%;
    height: 3rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background: var(--primary-color);
    color: white;
    fill: white;
}

.upload:hover {
    cursor: pointer;
    box-shadow: 2px 2px 3px grey;
}

.upload div:hover,
.upload label:hover,
.upload input:hover {
    cursor: pointer;
}

.csv-upload .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
}