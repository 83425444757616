
:root{
    --scroll-indicator-height: 1.25rem;
    --navbar-height: 40px;
    --navbar-color: #eee;
    --upload-file-dialog-width: 40rem;
    --primary-color: #4d7ac4;
    --secondary-color: rgb(60, 142, 207);
    --secondary-font-color: white;
    --secondary-button-hover: rgb(60, 200, 240);
    --main-color: #213040;
}

.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    background-color: var(--main-color);
    padding: 1rem;
    box-shadow: 3px 3px 4px #222;
}

.main-panel {
    height: 100%;
    width: 100%;
}



.state-info-popup {
    position: absolute;
    display: none;
    border: 1px solid grey;
    box-shadow: 1px 1px 3px grey;
    background: white;
    pointer-events: none;
}

table {
    border-collapse: collapse;
    border: 1px solid #666;
}

th, td {
    font-weight: normal;
    padding: .25rem;
    border: 1px solid #666;
}

.App .email-popup-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--main-color);
    padding: 1rem;
    box-shadow: 3px 3px 4px #222;
}