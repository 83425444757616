.map-container {
    width: 100%;
    height: calc(100% - var(--navbar-height));
}

#map-view-box {
    width: 100%;
    height: 100%;
    cursor: default;
}

.please-wait-message {
    font: 2rem sans-serif;
}

#map-view-box path:hover {
    stroke: #646464;
    stroke-width: 2px;
}

.zoom-controls-container {
    position: absolute;
    background: white;
    right: 1rem;
    bottom: 1rem;
}

.state-info-popup {
    background: #213040;
}

.state-info-popup  #state,
.state-info-popup #county,
.state-info-popup #value {
    min-width: 13rem;
}

.state-info-popup  #state {
    margin-top: 1rem;
    font-weight: bold;
}

.state-info-popup  #value {
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 1rem;
}
