.embed-to-your-site {
    display: flex;
    justify-self: center;
    align-items: center;
    color: white;
    text-decoration: underline;
}

.embed-to-your-site:hover {
    font-size: 1.02rem;
}